/* eslint-disable react-refresh/only-export-components */
// export { default as Collect } from './collect'

import type { Socket } from "socket.io-client"
import type Collect from './DomCollector'
import type { DOMChanges, DOMChangesMin, SessionEvent, SessionEventMin, UserProperty, UserPropertyMin } from "../../../types"

const WS_URL = import.meta.env.VITE_WS_URL as string

const KeyStoreVersion = '0.1'

enum ChangeType {
	WINDOW_RESIZE = '1',
	CUSTOM_EVENT = '2',
	DOM_READY = '3',
	ADD_NODES = '4',
	ATTR_CHANGE = '5',
	REMOVE_NODES = '6',
	SCROLL = '7',
	FOCUS = '8',
	MOUSE_MOVE = '9',
	MOUSE_CLICK = '10',
	MOUSE_OVER = '11',
	MOUSE_OUT = '12',
	PAGE_LOAD = '13',
	CHARACTER_DATA_CHANGE = '14',
	CSS_INSERT_RULE = '15',
	CSS_DELETE_RULE = '16',
	URL_CHANGE = '17',
	INPUT_CHANGE = '18',
	USER_PROPERTY = '19',
	EVENT = '20',
	CLIENT_IP = '21',
	MEDIA_INFO = '22',
	MOUSE_DOUBLE_CLICK = '23',
}

const SessionKey = `uxcam:${KeyStoreVersion}:session:key`
const SessionTime = `uxcam:${KeyStoreVersion}:session:time`
const SessionSeq = `uxcam:${KeyStoreVersion}:session:seq`
const SessionLastTime = `uxcam:${KeyStoreVersion}:session:lasttime`
const DeviceIDKey = `uxcam:${KeyStoreVersion}:deviceid`
const SessionAppKey = `uxcam:${KeyStoreVersion}:appkey`
const SessionTemporaryChanges = `uxcam:${KeyStoreVersion}:tmp_changes`
const SessionLoadCounter = `uxcam:${KeyStoreVersion}:load_counter`

const RequiredAPIS = [ 'WebSockets', 'CompressionStream', 'Blob', 'MutationObserver', 'URL', 'Proxy', 'CSSStyleSheet', 'CSSStyleSheet.insertRule', 'CSSStyleSheet.deleteRule', 'history', 'Promise', 'TransformStream' ]

export interface OcclusionOptions {
	url: ( url: string ) => string,
	queryParams: string[],
}

interface WebSDKOptions {
	wsURL?: string,
	appVersion?: string,
	debug?: boolean,
	min?: boolean,
	occlusion?: OcclusionOptions,
}

type GlobCallType = 'event' | 'setUserIdentity' | 'setUserProperty' | 'setUserProperties' | 'abort'
type GlobCall = [ GlobCallType, unknown, unknown? ]

interface WebSDKInit {
	__t: GlobCall[],
	__ak: string,
	__o: WebSDKOptions,
}

interface WindowWithSDK {
	uxc: WebSDKInit | SDKClient
	uxc_initialised?: true
}

const saveCookie = ( name: string, value: string, days: number ) => {
	// try to save the cookie to the root domain
	const domain = window.location.hostname
	const rootDomain = '.' + domain.split( '.' ).slice( -2 ).join( '.' )
	const expires = new Date()
	expires.setTime( expires.getTime() + days * 24 * 60 * 60 * 1000 )
	try {
		document.cookie = `${ name }=${ value };expires=${ expires.toUTCString() };domain=${ rootDomain };path=/`
		// check if the cookie was saved
		if ( getCookie( name ) !== value ) {
			document.cookie = `${ name }=${ value };expires=${ expires.toUTCString() };domain=${ domain };path=/`
			return
		}
	} catch ( e ) {
		document.cookie = `${ name }=${ value };expires=${ expires.toUTCString() };domain=${ domain };path=/`
	}
}

const getCookie = ( name: string ) => {
	const value = `; ${ document.cookie }`
	const parts = value.split( `; ${ name }=` )
	if ( parts.length === 2 ) {
		return parts.pop()?.split( ';' ).shift()
	}
}

const getDeviceId = () => {
	const cookieDeviceId = getCookie( DeviceIDKey )
	const localStorageDeviceId = localStorage.getItem( DeviceIDKey )
	const sessionStorageDeviceId = sessionStorage.getItem( DeviceIDKey )

	if ( cookieDeviceId ) {
		if ( !localStorageDeviceId ) {
			localStorage.setItem( DeviceIDKey, cookieDeviceId )
		}
		if ( !sessionStorageDeviceId ) {
			sessionStorage.setItem( DeviceIDKey, cookieDeviceId )
		}
		return cookieDeviceId
	}

	if ( localStorageDeviceId ) {
		if ( !cookieDeviceId ) {
			saveCookie( DeviceIDKey, localStorageDeviceId, 365 )
		}
		if ( !sessionStorageDeviceId ) {
			sessionStorage.setItem( DeviceIDKey, localStorageDeviceId )
		}
		return localStorageDeviceId
	}

	if ( sessionStorageDeviceId ) {
		if ( !cookieDeviceId ) {
			saveCookie( DeviceIDKey, sessionStorageDeviceId, 365 )
		}
		if ( !localStorageDeviceId ) {
			localStorage.setItem( DeviceIDKey, sessionStorageDeviceId )
		}
		return sessionStorageDeviceId
	}

	return null
}

const saveDeviceId = ( deviceId: string ) => {
	saveCookie( DeviceIDKey, deviceId, 365 )
	localStorage.setItem( DeviceIDKey, deviceId )
	sessionStorage.setItem( DeviceIDKey, deviceId )
}

class SDKClient {
	private socket: Socket | null = null
	private changesBucket: ( DOMChanges | DOMChangesMin )[] = []
	private binaryBucket: Uint8Array[][] = []
	private globalBucket: GlobCall[] = []

	private recordingStopped = false
	private lastSeq = 0
	private deviceId: string | null = null
	private sessionId: string | null = null
	private collector: Collect | null = null
	private sessionTime: number = 0
	private wsURL: string
	private appKey: string
	private debug: boolean
	private min: boolean
	private appVersion: string = 'unknown'

	public initialised: boolean = false

	private temporaryBucket: (DOMChanges | DOMChangesMin )[] = []

	static allAPIsMet = () => {
		const availableAPIs = SDKClient.getSupportedAPIs()
		return RequiredAPIS.every( ( api ) => availableAPIs.includes( api ) )
	}

	staticRequestIdleCallback = ( callback: () => void, force = false ) => {
		if ( force ) {
			callback()
			return
		}
		if ( window.requestIdleCallback ) {
			window.requestIdleCallback( callback )
		} else {
			setTimeout( callback, 100 )
		}
	}

	static getSupportedAPIs = () : string[] => {
		const supportedAPIs: string[] = []
		if ( window.WebSocket ) {
			supportedAPIs.push( 'WebSockets' )
		}
		if ( window.CompressionStream ) {
			supportedAPIs.push( 'CompressionStream' )
		}
		if ( window.Blob ) {
			supportedAPIs.push( 'Blob' )
		}
		if ( window.MutationObserver ) {
			supportedAPIs.push( 'MutationObserver' )
		}
		if ( window.URL ) {
			supportedAPIs.push( 'URL' )
		}
		if ( window.Proxy ) {
			supportedAPIs.push( 'Proxy' )
		}
		if ( window.CSSStyleSheet ) {
			supportedAPIs.push( 'CSSStyleSheet' )
		}
		if ( window.CSSStyleSheet && typeof window.CSSStyleSheet.prototype.insertRule === 'function' ) {
			supportedAPIs.push( 'CSSStyleSheet.insertRule' )
		}
		if ( window.CSSStyleSheet && typeof window.CSSStyleSheet.prototype.deleteRule === 'function' ) {
			supportedAPIs.push( 'CSSStyleSheet.deleteRule' )
		}
		if ( window.history ) {
			supportedAPIs.push( 'history' )
		}
		if ( window.Promise ) {
			supportedAPIs.push( 'Promise' )
		}
		if ( window.TransformStream ) {
			supportedAPIs.push( 'TransformStream' )
		}

		return supportedAPIs
	}

	private static getISODateTimeNow = () => {
		// e.g: 2021-08-10T12:00:00.000Z+05:30
		const now = new Date()
		// remove Z and T
		const pad = ( num: number ) => (num < 10 ? '0' : '') + num;

		// const isoString = now.toISOString().slice( 0, -1 ).replace( 'T', ' ' )
		const offset = now.getTimezoneOffset()
		const offsetHours = Math.floor( Math.abs( offset / 60 ) )
		const offsetMinutes = Math.abs( offset % 60 )
		const offsetString = `${ offsetHours < 10 ? '0' : '' }${ offsetHours }:${ offsetMinutes < 10 ? '0' : '' }${ offsetMinutes }`
		const sign = offset > 0 ? '-' : '+' // negative offset means positive sign

		const dateTime = now.getFullYear() +
			'-' + pad(now.getMonth() + 1) +
			'-' + pad(now.getDate()) +
			' ' + pad(now.getHours()) +
			':' + pad(now.getMinutes()) +
			':' + pad(now.getSeconds())

		return `${ dateTime } GMT${ sign }${ offsetString }`
	}

	private getTemporaryChanges = () => {
		const currentChanges = sessionStorage.getItem( SessionTemporaryChanges )
		return currentChanges ? ( JSON.parse( currentChanges ) as ( DOMChanges | DOMChangesMin )[] ) : [] as ( DOMChanges | DOMChangesMin )[]
	}
	private saveTemporaryChange = ( change: ( DOMChanges | DOMChangesMin ) | ( DOMChanges | DOMChangesMin )[] ) => {
		const changes = this.getTemporaryChanges()
		if ( Array.isArray( change ) ) {
			changes.push( ...change )
		} else {
			changes.push( change )
		}
		sessionStorage.setItem( SessionTemporaryChanges, JSON.stringify( changes ) )
	}
	private clearTemporaryChanges = () => {
		sessionStorage.removeItem( SessionTemporaryChanges )
	}

	// will generate a hex string of length
	private generateId = (length: number) => {
		const result: string[] = []
		const characters = 'abcdef0123456789'
		const charactersLength = characters.length
		for (let i = 0; i < length; i++) {
			result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
		}
		return result.join('')
	}

	private clearSession = () => {
		sessionStorage.removeItem( SessionKey )
		sessionStorage.removeItem( SessionTime )
		sessionStorage.removeItem( SessionSeq )
		sessionStorage.removeItem( SessionLastTime )
		sessionStorage.removeItem( SessionAppKey )
		sessionStorage.removeItem( SessionLoadCounter )
		sessionStorage.removeItem( SessionTemporaryChanges )
	}

	private onNewSessionTime = ( time: number ) => {
		this.sessionTime = time
	}

	private getSession = () => {

		/**
		 * Read SessionLastTime, if it is more than 10 minutes, reset the session
		 */

		const lastTime = sessionStorage.getItem( SessionLastTime )
		let reconnected = Boolean( lastTime )
		if ( lastTime ) {
			const lastTimeMs = parseInt( lastTime )
			const currentTimeMs = new Date().getTime()
			const diff = currentTimeMs - lastTimeMs

			const lastAppKey = sessionStorage.getItem( SessionAppKey )
			const hasNewAppKey = lastAppKey && lastAppKey !== this.appKey

			if ( diff > 10 * 60 * 1000 || hasNewAppKey ) {
				this.clearSession()
				reconnected = false
			}
		}

		const existingSessionId = sessionStorage.getItem( SessionKey )
		const existingSessionTime = sessionStorage.getItem( SessionTime )
		const existingSeq = sessionStorage.getItem( SessionSeq )
		const existingCounter = sessionStorage.getItem( SessionLoadCounter )

		const useSessionId = existingSessionId ? existingSessionId : SDKClient.generateUUID()
		const useSessionTime = existingSessionTime ? new Date( parseInt( existingSessionTime ) ).getTime() : null
		const useSeq = existingSeq ? parseInt( existingSeq ) + 500 : 0

		if ( !existingSessionId ) {
			sessionStorage.setItem( SessionKey, useSessionId )
			if ( useSessionTime ) {
				sessionStorage.setItem( SessionTime, useSessionTime.toString() )
			}
			sessionStorage.setItem( SessionSeq, useSeq.toString() )
			sessionStorage.setItem( SessionAppKey, this.appKey )
		}

		let sessionCounter = 0

		if ( !existingCounter ) {
			sessionStorage.setItem( SessionLoadCounter, '0' )
		} else {
			sessionCounter = parseInt( existingCounter )
			sessionStorage.setItem( SessionLoadCounter, ( sessionCounter + 1 ).toString() )
		}

		let deviceId = getDeviceId()
		if ( !deviceId ) {
			deviceId = this.generateId( 16 )
			saveDeviceId( deviceId )
		}

		this.deviceId = deviceId

		return {
			sessionId: useSessionId,
			startTime: useSessionTime,
			seq: useSeq,
			reconnected,
			deviceId,
			counter: sessionCounter,
		}

	}

	private loadPolyfills = async () => {
		const availableAPIs = SDKClient.getSupportedAPIs()
		const loadDeps: Promise<unknown>[] = []

		// cannot polyfill WebSockets
		if ( !availableAPIs.includes( 'WebSockets' ) ) {
			return
		}

		if ( !availableAPIs.includes( 'Promise' ) ) {
			const result = await import( 'promise-polyfill' )
			// merge the result into window
			window.Promise = result.default
		}

		// load polyfill for CompressionStream
		if ( !availableAPIs.includes( 'CompressionStream' ) ) {
			loadDeps.push(
				// eslint-disable-next-line no-async-promise-executor
				new Promise<void>( async ( resolve ) => {

					// @note: TransformStream is required for CompressionStream
					if ( !availableAPIs.includes( 'TransformStream' ) ) {
						const result = await import( 'web-streams-polyfill/polyfill' )
						// merge the result into window
						Object.assign( window, result )
					}

					const {
						makeCompressionStream
					} = await import( 'compression-streams-polyfill/ponyfill' )

					window.CompressionStream = makeCompressionStream( window.TransformStream )

					resolve()
				} )
			)
		}

		await Promise.all( loadDeps )

	}

	static generateUUID = () => {
		// custom UUID generator
		const s4 = () => {
			return Math.floor( ( 1 + Math.random() ) * 0x10000 ).toString( 16 ).substring( 1 )
		}
		return `${ s4() }${ s4() }-${ s4() }-${ s4() }-${ s4() }-${ s4() }${ s4() }${ s4() }`
	}

	private __pingInterval: Timer | null = null
	private __processInterval: Timer | null = null


	private processBinaryBucket = () => {
		if ( !this.binaryBucket.length ) { return }
		const {
			sessionId,
		} = this
		const chunks = this.binaryBucket.slice()
		this.binaryBucket = []

		Promise.all(
			chunks.map( async ( chunk ) => {
				const arrayBuffer = await new Blob( chunk ).arrayBuffer()
				const merged = new Uint8Array( arrayBuffer )
				return merged
			} )
		)
		.then( ( arrayBuffers ) => {
			const arrBuffLen = arrayBuffers.length
			for( let i = 0; i < arrBuffLen; i++ ) {
				this.socket!.emit( 'session_data', {
					sid: sessionId,
					data: arrayBuffers[ i ],
				} )
			}
		} )
	}

	private init = async () => {
		console.debug( 'UXCam Web SDK Initialised' )
		// try to load polyfills
		if ( !SDKClient.allAPIsMet() ) {
			await new Promise<void>( ( resolve ) => {
				this.staticRequestIdleCallback( () => {
					this.loadPolyfills()
						.then( resolve )
				} )
			} )

		}

		if ( !SDKClient.allAPIsMet() ) {
			const supportedAPIs = SDKClient.getSupportedAPIs()
			const missing = RequiredAPIS.filter( ( api ) => !supportedAPIs.includes( api ) )
			this.sendLog( `APIs not supported: ${ missing.join( ',' ) }` )
			return
		}

		const [
			socketIoModule,
			CollectModule,
		] = await new Promise<[
			typeof import( 'socket.io-client' ),
			typeof import( './DomCollector' ),
		]>( ( resolve ) => {
			this.staticRequestIdleCallback( () => {
				Promise.all( [
					import( 'socket.io-client' ),
					import( './DomCollector' ),
				] ).then( ( [ socketIoModule, CollectModule ] ) => {
					resolve( [ socketIoModule, CollectModule ] )
				} )
			} )
		} )

		const {
			sessionId,
			startTime,
			seq,
			reconnected,
			deviceId,
			counter,
		} = this.getSession()

		// save the session data to instance
		this.sessionId = sessionId
		this.lastSeq = seq
		this.deviceId = deviceId

		// if there was a start time save it to instance
		if ( startTime ) {
			this.sessionTime = startTime
		}

		const io = socketIoModule.io
		const { getDeviceData } = CollectModule
		const Collect = CollectModule.default

		this.socket = io(
			this.wsURL,
			{
				transports: [ 'websocket' ],
				query: {
					appKey: this.appKey,
					sessId: sessionId,
					reconnected: startTime ? 1 : 0,
				},
			}
		)

		const connectPromise = new Promise<void>( ( resolve ) => {
			const handleConnect = () => {
				this.socket!.off( 'connect', handleConnect )
				resolve()
			}
			this.socket!.on( 'connect', handleConnect )
			this.socket!.send( 'conn' )
		} )

		await connectPromise

		this.socket.on( 'kill', this.handleKilled )

		if ( !reconnected ) {
			this.socket.emit( 'session_init', {
				sessionId: sessionId,
				deviceId: deviceId,
				appKey: this.appKey,
				deviceTime: SDKClient.getISODateTimeNow(),
				deviceData: getDeviceData( {
					appVersion: this.appVersion ? String( this.appVersion ) : 'unknown',
				} ),
			} )
		}

		if ( this.scheduledAbort ) {
			this.socket.emit( 'session_abort', sessionId )
			this.deleteSess()
			return
		}

		this.__processInterval = setInterval( () => {
			this.processBinaryBucket()
		}, 200 )

		const collectChanges = async () => {
			const { recordingStopped } = this
			const changes = recordingStopped ?
				this.changesBucket.slice()
					.filter( ( change ) => {
						const useChange = change as DOMChanges
						const useChangeMin = change as DOMChangesMin

						switch( useChange.type ) {
							case 'EVENT':
							case 'USER_PROPERTY': {
									return true
							}
							default: {
								switch ( useChangeMin.ct ) {
									case ChangeType.EVENT:
									case ChangeType.USER_PROPERTY: {
											return true
									}
									default: {
										return false
									}
								}
							}

						}
					} )
				: this.changesBucket.slice()

			if ( changes.length || recordingStopped ) {
				this.changesBucket = []

				if ( recordingStopped ) {
					this.saveTemporaryChange( changes )
					return
				}

				// offload json stringify
				const blob = await new Promise<Blob>( ( resolve ) => {
					this.staticRequestIdleCallback( () => {
						return resolve( new Blob( [ JSON.stringify( changes ) ] ) )
					} )
				} )
				const readableStream = blob.stream()

				const chunks: Uint8Array[] = []

				readableStream.pipeThrough(
					new CompressionStream( 'gzip' )
				).pipeTo(
					new WritableStream( {
						write: ( chunk ) => {
							chunks.push( chunk )
						}
					} )
				).then( () => {
					this.binaryBucket.push( chunks )
					setTimeout( collectChanges, 200 )
				} )
				return
			}

			this.staticRequestIdleCallback( collectChanges )

		}

		collectChanges()

		window.addEventListener( 'beforeunload', () => {
			this.recordingStopped = true
			// @todo: alocate 500 seq for the temporary changes
			this.saveSess()
		} )

		this.collector = new Collect( {
			onChange: this.onChange,
			onSeqUpdate: this.onSeqUpdate,
			onNewStartTime: this.onNewSessionTime,
			startTime: startTime,
			seq: seq,
			min: this.min,
			sendLog: this.sendLog,
			// for debugging
			sessionCounter: counter,
			abort: this.abort,
			appKey: this.appKey,
		}, this.occlusionOptions )

		this.saveSess()

		// check if page was refreshed, if so, send an event uxc_page_refresh
		if ( window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD ) {
			this.event( 'uxc_page_refresh', {} )
		}

		this.processTemporaryBucket()

	}

	private processTemporaryBucket = () => {
		const globalBucked = this.globalBucket.slice()
		this.globalBucket = []

		globalBucked.forEach( ( data ) => {
			const type = data[ 0 ] as GlobCallType
			switch ( type ) {
				case 'event': {
					this.event( data[ 1 ] as string, data[ 2 ] as Record<string, unknown>)
					break
				}
				case 'setUserIdentity': {
					this.setUserIdentity( data[ 1 ] as string )
					break
				}
				case 'setUserProperties': {
					this.setUserProperties( data[ 1 ] as Record<string, string> )
					break
				}
				case 'setUserProperty': {
					this.setUserProperty( data[ 1 ] as string, data[ 2 ] as string )
					break
				}
				case 'abort': {
					this.abort()
					break
				}
			}
		} )

		this.temporaryBucket.forEach( ( data ) => {
			const minChange = data as DOMChangesMin
			const change = data as DOMChanges
			if ( minChange.ct ) {
				if ( minChange.ct === ChangeType.EVENT ) {
					this.event( minChange.n, minChange.p )
				}
				if ( minChange.ct === ChangeType.USER_PROPERTY ) {
					this.setUserProperty( minChange.k, minChange.v as string )
				}
			} else {
				if ( change.type === 'EVENT' ) {
					this.event( change.name, change.properties )
				}
				if ( change.type === 'USER_PROPERTY' ) {
					this.setUserProperty( change.key, change.value as string )
				}
			}
		} )
		this.temporaryBucket = []

		// get the temporary changes
		const changes = this.getTemporaryChanges()
		if ( changes.length ) {
			changes.forEach( ( change ) => {
				this.onChange( change )
			} )
			this.clearTemporaryChanges()
		}
	}

	private saveSess = () => {
		sessionStorage.setItem( SessionSeq, this.lastSeq.toString() )
		sessionStorage.setItem( SessionLastTime, new Date().getTime().toString() )
		sessionStorage.setItem( SessionTime, this.sessionTime!.toString() )
		sessionStorage.setItem( SessionKey, this.sessionId! )
		saveDeviceId( this.deviceId! )
	}

	private deleteSess = () => {
		sessionStorage.removeItem( SessionKey )
		sessionStorage.removeItem( SessionTime )
		sessionStorage.removeItem( SessionSeq )
		sessionStorage.removeItem( SessionLastTime )
		sessionStorage.removeItem( SessionAppKey )
		sessionStorage.removeItem( SessionLoadCounter )
	}

	private saveSessTimout: NodeJS.Timeout | null = null

	private onSeqUpdate = ( seq: number ) => {
		this.lastSeq = seq
		if ( this.saveSessTimout ) {
			clearTimeout( this.saveSessTimout )
		}
		this.saveSessTimout = setTimeout( () => {
			this.saveSess()
		}, 500 ) as NodeJS.Timeout
	}

	private sendLog = ( message: string ) => {
		const host = this.wsURL.replace( 'wss://', '' ).replace( 'ws://', '' )
		const url = `https://${host}/logs`
		const body = {
			message: `[FE Error]: ${ message }`,
			url: window.location.href,
		}
		fetch( url, {
			method: 'POST',
			body: JSON.stringify( body ),
			headers: {
				'Content-Type': 'application/json',
			},
		} )
	}

	static defaultOccludeUrlMapping = ( url: string ) => {
		return url
	}
	static defaultOccludeQueryParams: string[] = []

	private occlusionOptions: OcclusionOptions = {
		url: SDKClient.defaultOccludeUrlMapping,
		queryParams: SDKClient.defaultOccludeQueryParams,
	}

	static safeUrlOcclusion = ( fn: ( url: string ) => string ) => {
		return ( url: string ) => {
			try {
				return fn( url )
			} catch ( e ) {
				console.error( e )
				return url
			}
		}
	}

	constructor( appKey: string, options?: WebSDKOptions ) {
		const wsURL = options?.wsURL || WS_URL
		const debug = options?.debug || false
		const min = options?.min !== undefined ? Boolean( options.min ) : true

		this.occlusionOptions.url = SDKClient.safeUrlOcclusion( options?.occlusion?.url || SDKClient.defaultOccludeUrlMapping )
		this.occlusionOptions.queryParams = options?.occlusion?.queryParams || SDKClient.defaultOccludeQueryParams
		this.appVersion = options?.appVersion || 'unknown'
		this.globalBucket = ( ( window as unknown as WindowWithSDK ).uxc as WebSDKInit )?.__t || []

		this.debug = debug
		this.wsURL = wsURL
		this.appKey = appKey
		this.min = min;

		// modify global
		if ( ( window as unknown as { uxc_initialised?: true } ).uxc_initialised ) {
			return
		}
		( window as unknown as { uxc: SDKClient } ).uxc = this;
		( window as unknown as { uxc_initialised: true } ).uxc_initialised = true

		if ( !appKey ) {
			this.sendLog( 'App Key is required' )
			console.warn( 'App Key is required' )
			return
		}

		if ( document.readyState === 'complete' ) {
			this.init()
			return
		}

		const readyStateChange = () => {
			if ( document.readyState === 'complete' ) {
				document.removeEventListener( 'readystatechange', readyStateChange )
				this.init()
			}
		}

		document.addEventListener( 'readystatechange', readyStateChange )
	}

	private onChange = ( data: DOMChanges | DOMChangesMin ) => {
		if (
			this.recordingStopped
		) {
			const useChange = data as DOMChanges
			const useChangeMin = data as DOMChangesMin

			switch( useChange.type ) {
				case 'EVENT':
				case 'USER_PROPERTY': {
					this.saveTemporaryChange( data )
					return
				}
				default: {
					switch ( useChangeMin.ct ) {
						case ChangeType.EVENT:
						case ChangeType.USER_PROPERTY: {
							this.saveTemporaryChange( data )
							return
						}
						default: {
							return
						}
					}
				}
			}
		}
		if ( this.debug ) {
			console.log( data )
		}

		this.changesBucket.push( data )
	}

	private scheduledAbort: boolean = false
	public handleKilled = () => {
		this.abort( false )
	}
	public abort = ( emitToServer = true ) => {
		/**
		 * In case of client called abort, we should notify server of the abort
		 * In case server called abort, we should not notify server of the abort
		 */
		if ( emitToServer ) {
			this.socket?.emit( 'session_abort', this.sessionId )
		}
		this.scheduledAbort = true
		this.deleteSess()

		if ( this.__pingInterval ) {
			clearInterval( this.__pingInterval )
		}
		if ( this.__processInterval ) {
			clearInterval( this.__processInterval )
		}
		if (this.collector ) {
			this.collector.destroy()
		}

		setTimeout( () => {
			this.socket?.disconnect()
		}, 1000 )
	}

	public event = ( name: string, properties: Record<string, unknown> ) => {
		if ( this.collector ) {
			this.collector.handleEvent( name, properties )
			return
		}

		const change: SessionEvent | SessionEventMin = this.min ? {
			ct: ChangeType.EVENT,
			n: name,
			p: properties,
			t: 0,
			s: 0,
		} : {
			type: 'EVENT',
			name,
			properties,
			time: 0,
			seq: 0,
		}

		this.temporaryBucket.push( change )
	}

	public setUserIdentity = ( identity: string ) => {
		this.setUserProperty( 'kUXCam_UserIdentity', identity )
	}

	public setUserProperty = ( key: string, value: string ) => {
		if ( this.collector ) {
			this.collector.setUserProperty( key, value )
			return
		}

		const change: UserProperty | UserPropertyMin = this.min ? {
			ct: ChangeType.USER_PROPERTY,
			k: key,
			v: value,
			t: 0,
			s: 0,
		} : {
			type: 'USER_PROPERTY',
			key,
			value,
			time: 0,
			seq: 0,
		}

		this.temporaryBucket.push( change )
	}
	public setUserProperties = ( properties: Record<string, string> ) => {
		const keys = Object.keys( properties )
		for( let i = 0; i < keys.length; i++ ) {
			this.setUserProperty( keys[ i ], properties[ keys[ i ] ] )
		}
	}
}

/**
 * Integration example
<script type="text/javascript">
 	(function( appKey, opts ) {
		window.uxc = {
			__t: [],
			__ak: appKey,
			__o: opts,
			event: function( n, p ) {
				this.__t.push( [ 'event', n, p ] )
			},
			setUserIdentity: function( i ) {
				this.__t.push( [ 'setUserIdentity', i ] )
			},
			setUserProperty: function( k, v ) {
				this.__t.push( [ 'setUserProperty', k, v ] )
			},
			setUserProperties: function( p ) {
				this.__t.push( [ 'setUserProperties', p ] )
			},
			abort: function() {
				this.__t.push( [ 'abort' ] )
			}
		}
		var head = document.getElementsByTagName('head')[0];
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = '//websdk-recording.uxcam.com/index.js';
		script.async = true;
		script.defer = true;
		script.id = 'uxcam-web-sdk';
		script.crossOrigin = 'anonymous';
		head.appendChild(script);
		} )( '1vtfhooowv6cb0g', {
		appVersion: '1.0.0',
		occlusion: {
			queryPrams: [
				'access_token',
			],
			url: function( url )  {
				// own logic for masking
				...
				return url
			}
		}
	} );
</script>
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ( typeof window !== 'undefined' && !( ( window as unknown as WindowWithSDK )?.uxc_initialised ) ) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const useWindow = window as unknown as WindowWithSDK
	const init = useWindow.uxc as WebSDKInit
	new SDKClient( init.__ak, init.__o )
}
